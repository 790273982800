<template>
  <b-row>
    <b-col v-if="show" md="6">
      <b-card title="Merchant Top Ranking By Amount (USD)">
        <e-charts
          ref="amountChart"
          class="chart"
          autoresize
        />
      </b-card>
    </b-col>
    <b-col v-if="show" md="6">
      <b-card title="Merchant Top Ranking By Count">
        <e-charts
          ref="countChart"
          class="chart"
          autoresize
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import {
  GridComponent, TooltipComponent, ToolboxComponent, LegendComponent,
} from 'echarts/components'

use([
  CanvasRenderer,
  // LabelLayout,
  BarChart,
  LineChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
])

export default {
  components: {
    BCard,
    BRow,
    BCol,

    ECharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      peiChartOption: {
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: p => `${p.name} : ${this.formatNum(p.value)} (${p.percent}%)`,
        },
        grid: {
          left: '10%',
        },
        series: [
          {
            name: 'Merchant Name',
            type: 'pie',
            radius: '50%',
            // label: {
            //   show: true,
            //   position: 'center',
            //   formatter: p => `${p.name} : ${this.formatNum(p.value)} (${p.percent})`,
            // },
            data: [],
          },
        ],
      },
    }
  },
  watch: {
    data: {
      handler(newData) {
        if (!newData) {
          return
        }
        this.drawGraph(newData)
      },
    },
  },
  methods: {
    formatNum(strNum) {
      if (strNum.length <= 3) {
        return strNum
      }
      if (!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
        return strNum
      }
      const a = RegExp.$1
      let b = RegExp.$2
      const c = RegExp.$3
      const re = new RegExp()
      re.compile('(\\d)(\\d{3})(,|$)')
      while (re.test(b)) {
        b = b.replace(re, '$1,$2$3')
      }
      return `${a}${b}${c}`
    },
    drawGraph(merchantsData) {
      const amountList = []
      const countList = []

      merchantsData.forEach(item => {
        const amount = { value: item.dollar_amount, name: item.merchant_name }
        amountList.push(amount)

        const count = { value: item.count, name: item.merchant_name }
        countList.push(count)
      })

      this.show = true
      setTimeout(() => {
        this.$refs.amountChart.setOption(this.peiChartOption)
        this.$refs.countChart.setOption(this.peiChartOption)
      }, 200)

      setTimeout(() => {
        const amountChartOption = this.peiChartOption
        amountChartOption.series[0].data = amountList
        this.$refs.amountChart.setOption(amountChartOption)

        const countChartOption = this.peiChartOption
        countChartOption.series[0].data = countList
        this.$refs.countChart.setOption(countChartOption)
      }, 200)
    },
  },
}
</script>

<style lang="scss">
.chart {
  height: 400px;
}
</style>
