<template>
  <b-row v-if="$can('read', 'Analytics.Method')">
    <b-col cols="12">
      <method-analytics-filters @search="search" @download="download" />
    </b-col>
    <b-col v-if="reportOptional.length > 0" cols="12">
      <b-card>
        <b-form-checkbox v-model="reportSelected" value="Daily" inline>
          Daily Report
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="Monthly" class="ml-2" inline>
          Monthly Report
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="merchantDoughnut" class="ml-2" inline>
          Merchant Top 10 Doughnut
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="appDoughnut" class="ml-2" inline>
          APP Top 10 Doughnut
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="merchantTopLine" class="ml-2" inline>
          Merchant Top 30 Line
        </b-form-checkbox>
        <b-form-checkbox v-model="reportSelected" value="appTopLine" class="ml-2" inline>
          APP Top 30 Line
        </b-form-checkbox>
      </b-card>
    </b-col>
    <b-col v-if="reportSelected.includes('Daily')" cols="12">
      <daily-report :data="daily" />
    </b-col>
    <b-col v-if="reportSelected.includes('Monthly')" cols="12">
      <monthly-report :data="monthly" />
    </b-col>
    <b-col v-if="reportSelected.includes('merchantDoughnut')" cols="12">
      <merchant-doughnut-report :data="merchantDoughnut" />
    </b-col>
    <b-col v-if="reportSelected.includes('appDoughnut')" cols="12">
      <app-doughnut-report :data="appDoughnut" />
    </b-col>
    <b-col v-if="reportSelected.includes('merchantTopLine')" cols="12">
      <merchant-top-line-report :data="merchantTopLine" :params="params" />
    </b-col>
    <b-col v-if="reportSelected.includes('appTopLine')" cols="12">
      <app-top-line-report :data="appTopLine" :params="params" />
    </b-col>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BCard, BOverlay,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'

import MethodAnalyticsFilters from './MethodAnalyticsFilters.vue'
import DailyReport from './DailyReport.vue'
import MonthlyReport from './MonthlyReport.vue'
import MerchantDoughnutReport from './MerchantDoughnutReport.vue'
import AppDoughnutReport from './AppDoughnutReport.vue'
import AppTopLineReport from './AppTopLineReport.vue'
import MerchantTopLineReport from './MerchantTopLineReport.vue'

import useDashboardJs from './dashboard'

const {
  fetchMerchantDoughnut,
  fetchMerchantTopLine,
  fetchMerchantAppDoughnut,
  fetchMerchantAppTopLine,
  fetchDaily,
  fetchMonthly,
  downloadAnalyticsReports,
} = useDashboardJs()

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BCard,
    BOverlay,

    MethodAnalyticsFilters,
    DailyReport,
    MonthlyReport,
    MerchantDoughnutReport,
    AppDoughnutReport,
    AppTopLineReport,
    MerchantTopLineReport,
  },
  data() {
    return {
      loading: false,
      reportOptional: [],
      reportSelected: [],
      merchantDoughnut: null,
      appDoughnut: null,
      daily: null,
      monthly: null,
      merchantTopLine: null,
      appTopLine: null,
      params: {},
    }
  },
  watch: {
    reportSelected: function redraw(value) {
      const {
        daily, monthly, merchantDoughnut, appDoughnut, merchantTopLine, appTopLine,
      } = this

      if (value.includes('Daily')) {
        this.daily = null
      }
      if (value.includes('Monthly')) {
        this.monthly = null
      }
      if (value.includes('merchantDoughnut')) {
        this.merchantDoughnut = null
      }
      if (value.includes('appDoughnut')) {
        this.appDoughnut = null
      }
      if (value.includes('merchantTopLine')) {
        this.merchantTopLine = null
      }
      if (value.includes('appTopLine')) {
        this.appTopLine = null
      }
      setTimeout(() => {
        if (value.includes('Daily')) {
          this.daily = daily
        }
        if (value.includes('Monthly')) {
          this.monthly = monthly
        }
        if (value.includes('merchantDoughnut')) {
          this.merchantDoughnut = merchantDoughnut
        }
        if (value.includes('merchantTopLine')) {
          this.merchantTopLine = merchantTopLine
        }
      }, 500)
      setTimeout(() => {
        if (value.includes('appDoughnut')) {
          this.appDoughnut = appDoughnut
        }
        if (value.includes('appTopLine')) {
          this.appTopLine = appTopLine
        }
      }, 1000)
    },
  },
  methods: {
    search(params) {
      this.reportOptional = []
      this.reportSelected = []
      this.daily = null
      this.monthly = null
      this.merchantDoughnut = null
      this.appDoughnut = null
      this.merchantTopLine = null
      this.appTopLine = null
      this.loading = true
      this.params = params
      fetchDaily(
        params,
        data => {
          this.daily = data
          this.reportOptional.push('Daily')
          this.reportSelected.push('Daily')
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch daily data fail with ${fail}.`)
        },
      )

      fetchMonthly(
        params,
        data => {
          this.monthly = data
          this.reportOptional.push('Monthly')
          this.reportSelected.push('Monthly')
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch monthly data fail with ${fail}.`)
        },
      )

      fetchMerchantDoughnut(
        params,
        data => {
          this.merchantDoughnut = data
          this.reportOptional.push('merchantDoughnut')
          this.reportSelected.push('merchantDoughnut')
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch merchant analysis data fail with ${fail}.`)
        },
      )

      setTimeout(() => {
        const param1 = {
          app_id: params.app_id,
          date_mode: params.date_mode,
          end_time: params.end_time,
          merchant_no: params.merchant_no,
          method: params.method,
          start_time: params.start_time,
          status: '20000',
        }
        fetchMerchantTopLine(
          param1,
          data => {
            this.merchantTopLine = data
            this.reportOptional.push('merchantTopLine')
            this.reportSelected.push('merchantTopLine')
            this.loading = false
          },
          fail => {
            this.loading = false
            showToast(this, 'Warning', `fetch merchant analysis data fail with ${fail}.`)
          },
        )

        if (params.merchant_no !== '') {
          fetchMerchantAppTopLine(
            param1,
            data => {
              this.appTopLine = data
              this.reportOptional.push('appTopLine')
              this.reportSelected.push('appTopLine')
            },
            fail => {
              showToast(this, 'Warning', `fetch app analysis data fail with ${fail}.`)
            },
          )
        }
      }, 1000)

      if (params.merchant_no !== '') {
        fetchMerchantAppDoughnut(
          params,
          data => {
            this.appDoughnut = data
            this.reportOptional.push('appDoughnut')
            this.reportSelected.push('appDoughnut')
          },
          fail => {
            showToast(this, 'Warning', `fetch app analysis data fail with ${fail}.`)
          },
        )
      }
    },
    download(params) {
      this.loading = true
      downloadAnalyticsReports(
        params,
        response => {
          this.loading = false
          const { headers } = response
          const contentType = headers['content-type']

          if (response.data) {
            const blob = new Blob([response.data], {
              type: contentType,
            })

            const contentDisposition = headers['content-disposition']
            let fileName = 'unknown'
            if (contentDisposition) {
              fileName = window.decodeURI(headers['content-disposition'].split('=')[1])
            }
            this.downFile(blob, fileName)
          }
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `fetch daily data fail with ${fail}.`)
        },
      )
    },
    downFile(blob, fileName) {
      if ('download' in document.createElement('a')) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      } else {
        window.navigator.msSaveBlob(blob, fileName)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
