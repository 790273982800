<template>
  <b-card>
    <div class="d-sm-flex justify-content-between align-items-center mb-3">
      <h4 class="card-title mb-50 mb-sm-0">
        Merchant Top {{ topCount }}
      </h4>
    </div>
    <b-row>
      <b-col md="3">
        <v-select
          v-model="topCurrency"
          :options="topCurrencyOptions"
          :reduce="val => val.value"
          :clearable="false"
          input-id="v-select-top-count"
        />
      </b-col>
      <b-col md="3">
        <v-select
          v-model="topCount"
          :options="topCountOptions"
          :reduce="val => val.value"
          :clearable="false"
          :searchable="false"
          input-id="v-select-top-count"
        />
      </b-col>
      <b-col md="3">
        <v-select
          v-model="topStatus"
          :options="topStatusOptions"
          :reduce="val => val.value"
          :clearable="false"
          :searchable="false"
          input-id="v-select-top-status"
        />
      </b-col>
      <b-col md="3">
        <v-select
          v-model="topType"
          :options="topTypeOptions"
          :reduce="val => val.value"
          :clearable="false"
          :searchable="false"
          input-id="v-select-top-type"
        />
      </b-col>
    </b-row>

    <!-- chart -->
    <e-charts
      ref="merchantTopLineChart"
      class="chart"
    />
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import vSelect from 'vue-select'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart, PieChart } from 'echarts/charts'
import {
  GridComponent, TooltipComponent, ToolboxComponent, LegendComponent,
} from 'echarts/components'
import { showToast } from '@/libs/tool'
import useDashboardJs from './dashboard'

const {
  fetchMerchantTopLine,
} = useDashboardJs()

use([
  CanvasRenderer,
  // LabelLayout,
  BarChart,
  LineChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
])

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    ECharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      topCountOptions: [
        { label: 'TOP-10', value: 10 },
        { label: 'TOP-20', value: 20 },
        { label: 'TOP-30', value: 30 },
      ],
      topTypeOptions: [
        { label: 'TOTAL AMOUNT', value: 1 },
        { label: 'TOTAL COUNT', value: 2 },
      ],
      topCurrencyOptions: [
        { label: 'TOTAL-IN-USD', value: '' },
      ],
      topStatusOptions: [
        { label: 'All', value: '' },
        { label: 'Initial', value: '10001' },
        { label: 'Authorized', value: '17000' },
        { label: 'Processing', value: '18000' },
        { label: 'RiskControll', value: '27000' },
        { label: 'Cancelled', value: '25000' },
        { label: 'Success', value: '20000' },
        { label: 'Dispute', value: '30000' },
        { label: 'Refused', value: '21000' },
        { label: 'OpenRefund', value: '22005' },
        { label: 'RefundProcessing', value: '22006' },
        { label: 'Refunded', value: '22000' },
        { label: 'ChargeBack', value: '23000' },
        { label: 'Fail', value: '40000' },
        { label: 'Expired', value: '26000' },
      ],
      topCount: 10,
      topStatus: '20000',
      topCurrency: '',
      topType: 1,
      topOptions: {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          top: '5%',
          right: '0%',
          bottom: '5%',
          selector: [
            {
              type: 'all or inverse',
              // 可以是任意你喜欢的 title
              title: 'Quick',
            },
          ],
          data: [],
        },
        grid: {
          left: '3%',
          right: '20%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [],
      },
      summaryGroup: {},
      searchParams: {},
    }
  },
  watch: {
    data: {
      handler(newData) {
        if (!newData) {
          return
        }
        this.summaryGroup = newData
        this.initCurrencyOps()
        this.calTotal(newData)
      },
    },
    params: {
      handler(params) {
        if (!params) {
          return
        }
        this.searchParams = params
      },
    },
    topType: function topType() {
      this.calTotal()
    },
    topStatus: function topStatus() {
      this.refreshData()
    },
    topCount: function topCount() {
      this.refreshData()
    },
    topCurrency: function topCurrency() {
      this.refreshData()
    },
  },
  methods: {
    initCurrencyOps() {
      const currencies = []
      currencies.push({ label: 'TOTAL-IN-USD', value: '' })
      this.summaryGroup.currencies.forEach(x => {
        const item = { label: x, value: x }
        currencies.push(item)
      })
      this.topCurrencyOptions = currencies
    },
    refreshData() {
      this.params.page_size = this.topCount
      if (this.topStatus !== '') {
        this.params.status = this.topStatus
      } else {
        this.params.status = null
      }
      this.params.currency = this.topCurrency
      const requestParam = this.params
      const that = this
      that.$showLoading()
      fetchMerchantTopLine(
        requestParam,
        data => {
          this.summaryGroup = data
          this.loading = false
          this.calTotal()
          that.$hideLoading()
        },
        fail => {
          this.loading = false
          that.$hideLoading()
          showToast(this, 'Warning', `fetch merchant analysis data fail with ${fail}.`)
        },
      )
    },
    calTotal() {
      this.topOptions.legend.data = this.summaryGroup.legend
      this.topOptions.xAxis.data = this.summaryGroup.dates
      if (this.topType === 1) {
        this.topOptions.series = this.summaryGroup.summaries
      } else {
        this.topOptions.series = this.summaryGroup.counts
      }
      setTimeout(() => {
        this.$refs.merchantTopLineChart.setOption(this.topOptions, true)
      }, 200)
    },
  },
}
</script>

<style lang="scss">
.chart {
  height: 400px;
}
</style>
